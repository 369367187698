import { create_adviser, delete_adviser, recover_adviser, update_adviser } from '@/api/cerp-model';
import { query_custom_data_view } from '@/api/sandbox-model';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { btn_list, event_obj, head_btn_list, slotList } from '@/components/page-model3/cerp/cerp-adviser-config';
import { onMounted, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { getDate, getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;
const { t } = useI18n();

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const search_config: SearchConfig = {
  settings: {},
  fields: [],
  rules: {},
  model: reactive({}),
};
const search_options = {};

const edit_model_config: SearchConfig = {
  settings: {},
  fields: [],
  rules: {},
  model: reactive({}),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {},
  fields: [],
  rules: {},
  model: reactive({}),
};
const update_model_config_options = {};

const titleList = {
  title: '顾问管理',
  addModalTitle: '新建顾问',
  updateTitle: '修改顾问',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: false, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    adviser_id: 'id',
  },
  delete: {
    adviser_id: 'id',
  },
  recover: {
    adviser_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    adviser_id: 'id',
  },
};

const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({ type: 'custom', field_true: '可用', field_false: '禁用' },
  {
    type: 'custom',
    slotName: 'worker_status',
    field_1: '-无-',
    field_2: '工作中',
    field_3: '结束',
    field_4: '申请签证中',
    field_5: '尚未开始',
    field_6: '潜在雇员',
    field_7: '离职',
    field_8: '待定',
  });

export default () => {
  const route = useRoute();
  search_config.model.search_text = route.query.q;
  onMounted(() => {
    watch(
      () => route.query,
      () => {
        if (route.path == '/adviser-manage/cerp-adviser' && route.query.q) {
          // search_config.model.search_text = route.query.q;
          event_obj.emit('refresh_search', { search_text: route.query.q });
        }
      },
    );
  });
  const baseColumns = [
    {
      title: t('page.cerp.adviser.editModal.column.title.index'),
      dataIndex: 'index',
      width: 50,
      fixed: 'left',
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.employee_number'),
      width: 80,
      dataIndex: 'employee_number',
      checked: true,

      fixed: 'left',
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.full_name'),
      width: 120,
      dataIndex: 'full_name',
      checked: true,

      fixed: 'left',
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.other_name'),
      width: 120,
      dataIndex: 'other_name',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.id'),
      width: 80,
      dataIndex: 'id',
      checked: false,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.title'),
      width: 120,
      dataIndex: 'title',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.birth_date'),
      width: 130,
      dataIndex: 'birth_date',
      checked: true,
      content: (record: any) => {
        return getDate(record.birth_date);
      },
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.nationality__name'),
      width: 130,
      dataIndex: 'nationality__name',
      checked: true,

    },
    {
      title: t('page.cerp.adviser.editModal.column.title.phone'),
      width: 120,
      dataIndex: 'phone',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.mobile'),
      width: 120,
      dataIndex: 'mobile',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.personal_email'),
      width: 200,
      dataIndex: 'personal_email',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.work_email'),
      width: 180,
      dataIndex: 'work_email',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.wechat'),
      width: 180,
      dataIndex: 'wechat',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.whats_app'),
      width: 150,
      dataIndex: 'whats_app',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.qq'),
      width: 150,
      dataIndex: 'qq',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.skypelid'),
      width: 150,
      dataIndex: 'skypelid',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.person_type__name'),
      width: 150,
      dataIndex: 'person_type__name',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.status__name'),
      width: 150,
      dataIndex: 'status',
      checked: true,

      content: (record: any) => {
        let color = '';
        switch (record.status) {
          case 1:
            color = t('-无-');
            break;
          case 2:
            color = t('工作中');
            break;
          case 3:
            color = t('结束');
            break;
          case 4:
            color = t('申请签证中');
            break;
          case 5:
            color = t('尚未开始');
            break;
          case 6:
            color = t('尚未开始');
            break;
          case 7:
            color = t('尚未开始');
            break;
          case 8:
            color = t('待定');
            break;
          default:
            color = '';
            break;
        }
        return color;
      },
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.creater'),
      width: 150,
      dataIndex: 'creater',
      checked: true,
    },
    {
      title: t('是否银行账户信息齐全'),
      width: 200,
      dataIndex: 'is_bank_account_intact',
      checked: true,
      cellComponent: 'boolean',
      name1: t('是'),
      name2: t('否'),
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('是'), value: true },
        { text: t('否'), value: false },
      ],
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.home_address__display_name'),
      width: 150,
      dataIndex: 'home_address__display_name',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.work_address__display_name'),
      width: 150,
      dataIndex: 'work_address__display_name',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.pay_currency__currency'),
      width: 100,
      dataIndex: 'pay_currency__currency',
      checked: true,

    },
    {
      title: t('page.cerp.adviser.editModal.column.title.person_note'),
      width: 350,
      dataIndex: 'person_note',
      checked: true,
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.create_time'),
      width: 150,
      dataIndex: 'create_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.create_time);
      },
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.update_time'),
      width: 150,
      dataIndex: 'update_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.update_time);
      },
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.is_active'),
      width: 110,
      dataIndex: 'is_active',
      checked: true,
      cellComponent: 'boolean',
      name1: t('可用'),
      name2: t('禁用'),
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('可用'), value: true },
        { text: t('禁用'), value: false },
      ],
    },
    {
      title: t('page.cerp.adviser.editModal.column.title.action'),
      width: 100,
      dataIndex: 'action',
      checked: false,
      cellComponent: 'action',
      fixed: 'right',
    },
  ];
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_custom_data_view,
    addData: create_adviser,
    updateData: update_adviser,
    removeData: delete_adviser,
    recoverData: recover_adviser,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
