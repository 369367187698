<template>
  <div class="ant-pro-table">
    <a-card :body-style="{ padding: 0 }" ref="elRef">
      <div class="ant-pro-table-list-toolbar">
        <div class="ant-pro-table-list-toolbar-container">
          <div class="ant-pro-table-list-toolbar-left">
            <div class="ant-pro-table-list-toolbar-title">
              <a-button
                type="link"
                v-if="!is_show_table && is_show_table_btn"
                @click="is_show_table = !is_show_table"
              >
                {{ t('展开') }}
              </a-button>
              <a-button
                type="link"
                v-if="is_show_table && is_show_table_btn"
                @click="is_show_table = !is_show_table"
              >
                {{ t('收起') }}
              </a-button>
              <a-badge :count="state.total"  :offset="[18,5]" :overflowCount="999">
                {{ t(title) }}
              </a-badge>
            </div>
            <component
              v-if="headerComponent"
              :is="headerComponent"
              v-bind="headerComponentObj"
            ></component>
          </div>
          <div class="ant-pro-table-list-toolbar-right" v-if="is_show_toolbar">
            <a-select
              v-if="is_custom_pagination"
              size="small"
              v-model:value="select_model.pageSize"
              style="min-width: 100px; margin-top: 10px; margin-left: 5px"
              :disabled="false"
              :allow-clear="false"
            >
              <a-select-option :value="20">显示20条</a-select-option>
              <a-select-option :value="50">显示50条</a-select-option>
              <a-select-option :value="100">显示100条</a-select-option>
              <a-select-option :value="200">显示200条</a-select-option>
              <a-select-option :value="500">显示500条</a-select-option>
              <a-select-option :value="1000">显示1000条</a-select-option>
            </a-select>
            <a-space align="center">
              <a-button
                v-if="titleList.is_create"
                style="margin-right: 3px"
                type="primary"
                size="small"
                @click="handleAdd"
              >
                <plus-outlined />
                {{ titleList.addModalTitle }}
              </a-button>
              <a-button
                v-for="btn in head_btn_list"
                size="small"
                style="margin-right: 3px"
                :key="btn.name"
                @click="btn.cb(search.requestParams.adviser, state.dataSource)"
                :type="btn.clazz"
              >
                {{ (t && t(`${btn.name}`)) || btn.name }}
              </a-button>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('收起展开')">
                  <a-switch
                    :checked-children="t('展开')"
                    :un-checked-children="t('收起')"
                    v-model:checked="is_show_table"
                  />
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('配置')">
                  <setting-outlined @click="handleQueryDataSetting" />
                </a-tooltip>
              </div>
              <div
                class="ant-pro-table-list-toolbar-setting-item"
                v-if="configState.is_column_config"
              >
                <a-tooltip :title="t('基本列信息')">
                  <a-button size="small" type="primary" danger @click="update_column_org_config">
                    {{ t('设为基础配置') }}
                  </a-button>
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item">
                <a-tooltip :title="t('展示自定义列')">
                  <a-switch
                    :checked-children="t('用户')"
                    :un-checked-children="t('原始')"
                    v-model:checked="configState.checkAll"
                    @change="column_change()"
                  />
                </a-tooltip>
              </div>
              <div
                class="ant-pro-table-list-toolbar-setting-item"
                v-if="configState.showCheckAction"
              >
                <a-tooltip :title="t('显示/隐藏操作列')">
                  <a-switch
                    :checked-children="t('操作')"
                    :un-checked-children="t('操作')"
                    v-model:checked="configState.checkAction"
                    @change="columnAction()"
                  />
                </a-tooltip>
              </div>
              <div class="ant-pro-table-list-toolbar-setting-item" v-if="is_show_excel">
                <a-tooltip :title="t('导出Excel')">
                  <export-excel-modal
                    :column-list="dynamicColumnItems"
                    :datasource="state.dataSource"
                    :filename="titleList.title"
                    :columns="dynamicColumns"
                    :slotList="slotList"
                    :pageSize="state.pageSize"
                    :current="state.current"
                  />
                </a-tooltip>
              </div>
            </a-space>
            <div
              class="ant-pro-table-list-toolbar-divider"
              v-if="is_show_refresh || is_show_change_column || is_show_fullscreen"
            >
              <a-divider type="vertical" />
            </div>
            <div class="ant-pro-table-list-toolbar-setting-item" v-if="is_show_refresh">
              <a-tooltip :title="t('刷新')">
                <reload-outlined @click="handleTableChange" />
              </a-tooltip>
            </div>
            <div class="ant-pro-table-list-toolbar-setting-item">
              <a-tooltip :title="t('密度')">
                <a-dropdown :trigger="['click']" placement="bottomRight">
                  <column-height-outlined />
                  <template #overlay>
                    <a-menu
                      style="width: 80px"
                      :selected-keys="[state.tableSize]"
                      @click="
                          ({ key }) => {
                            state.tableSize = key;
                          }
                        "
                    >
                      <a-menu-item key="default">
                        <a href="javascript:">{{ t('默认') }}</a>
                      </a-menu-item>
                      <a-menu-item key="middle">
                        <a href="javascript:">{{ t('中等') }}</a>
                      </a-menu-item>
                      <a-menu-item key="small">
                        <a href="javascript:">{{ t('紧凑') }}</a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </a-tooltip>
            </div>
            <div class="ant-pro-table-list-toolbar-setting-item" v-if="is_show_change_column">
              <a-popover
                placement="bottomRight"
                arrowPointAtCenter
                trigger="click"
                overlayClassName="ant-pro-table-column-setting-overlay"
              >
                <template #title>
                  <div class="ant-pro-table-column-setting-title">
                    <a-checkbox
                      v-model:checked="columnState.checkAll"
                      :indeterminate="columnState.indeterminate"
                      @change="handleColumnAllClick"
                    >
                      {{ t('列展示') }}
                    </a-checkbox>
                    <a v-if="!configState.is_column_config" @click="switch_column_config">{{ t('配置') }}</a>
                    <a @click="switch_column_config" v-if="configState.is_column_config">
                      {{ t('关闭配置') }}
                    </a>
                  </div>
                </template>
                <template #content>
                  <span class="ant-pro-table-column-setting-list">
                    <drag-container
                      style="width: 280px; height: 300px; overflow: scroll"
                      lockAxis="y"
                      dragClass="ant-pro-table-drag-ghost"
                      dropClass="ant-pro-table-drop-ghost"
                      @drop="
                        ({ removedIndex, addedIndex }) => {
                          move(removedIndex, addedIndex);
                          if (!configState.is_column_config) {
                            update_column_data();
                          }
                        }
                      "
                    >
                      <draggable :key="column.key" v-for="column in dynamicColumnItems">
                        <div
                          class="ant-pro-table-column-setting-list-item"
                          v-show="configState.is_column_config ? true : column.org_checked"
                        >
                          <drag-icon />
                          <a-checkbox
                            :checked="column.checked"
                            @change="
                              $event => {
                                handleColumnChange($event, column);
                                if (!configState.is_column_config) {
                                  update_column_data();
                                }
                              }
                            "
                          >
                            {{ column.label }}
                          </a-checkbox>
                          <a-switch
                            v-if="configState.is_column_config"
                            :checked-children="t('显示')"
                            :un-checked-children="t('隐藏')"
                            v-model:checked="column.org_checked"
                            @change="
                              () => {
                                if (!column.org_checked) {
                                  column.checked = false;
                                }
                                handleColumnChange({ target: { checked: column.checked } }, column);
                              }
                            "
                          />
                        </div>
                      </draggable>
                    </drag-container>
                  </span>
                </template>
                <a-tooltip :title="t('列设置')">
                  <setting-outlined />
                </a-tooltip>
              </a-popover>
            </div>
            <div class="ant-pro-table-list-toolbar-setting-item" v-if="is_show_fullscreen">
              <a-tooltip :title="screenState ? t('退出全屏') : t('全屏')">
                <fullscreen-outlined v-if="!screenState" @click="setFull" />
                <fullscreen-exit-outlined v-else @click="exitFull" />
              </a-tooltip>
            </div>
          </div>
        </div>
      </div>
      <s-table
        v-if="is_show_table"
        :bordered="true"
        :height="tableHeight.y"
        :size="state.tableSize"
        v-model:columns="dynamicColumns"
        :data-source="state.dataSource"
        :indentSize="0"
        :autoRowHeight="true"
        :wrapText="true"
        :row-height="rowheight"
        :rowKey="rowkey"
        :loading="state.loading"
        :virtual="virtual || is_virtual"
        :customRow="custom"
        :customHeaderCell="customHeaderCell"
        :customCell="customCell"
        :rowClassName="setRowClassName"
        stripe
        sticky
        summary-fixed
        :pagination="
          is_pagination === false
            ? false
            : {
                current: state.current,
                pageSize: state.pageSize,
                total: state.total,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['50', '100', '200', '500', '1000', '2000'],
                position: position,
              }
        "
        @change="handleTableChange"
        @resizeColumn="resizeColumn"
        :rowSelection="rowSelection"
        :expandedRowRender="expandedRowRender"
        v-model:selectedRowKeys="selectedRowKeys"
      >
        <template #headerCell="{ title, column }">
          <div v-if="column.headerCell">
            {{ title }}
            <a-button
              :danger="column?.headerCell?.danger"
              :type="column?.headerCell.type"
              :disabled="column?.headerCell.disabled"
              :htmlType="column?.headerCell.htmlType"
              :loading="column?.headerCell.loading"
              :shape="column?.headerCell.shape"
              :size="column?.headerCell?.size ? column.headerCell.size : 'small'"
              @click="column?.headerCell.cb ? column.headerCell.cb(column) : null"
            >
              {{ column?.headerCell?.title }}
              <template #icon v-if="column?.headerCell?.icon">
                <setting-outlined v-if="column?.headerCell?.icon == 'settingOutlined'" />
                <span v-else></span>
              </template>
            </a-button>
          </div>
          <div v-else>{{ title }}</div>
        </template>
        <template #bodyCell="{ column, index, text, record }">
          <template v-if="column.dataIndex == 'index'">
            {{ index + 1 + state.pageSize * (state.current - 1) }}
          </template>
          <component
            v-else-if="column.cellComponent"
            :is="'Cell_' + column.cellComponent"
            v-bind="{
              column: column,
              index: index,
              text: text,
              record: record,
              pageSize: state.pageSize,
              dataSource: state.dataSource,
              current: state.current,
              titleList: titleList,
              column_normal_btn_list: column_normal_btn_list,
              column_dropdown_btn_dict: column_dropdown_btn_dict,
              handleOpenEdit: handleOpenEdit,
              handleDelete: handleDelete,
              handleRecover: handleRecover,
              requestParams: search.requestParams,
            }"
          ></component>
          <template v-else>
            <span :style="column.color ? column.color(record, text) : null">
              {{ column.content ? column.content(record, text) : text }}
            </span>
          </template>
        </template>

        <template #expandedRowRender="{ record }" v-if="expandedRowRenderComponent">
          <component :is="expandedRowRenderComponent(record)" v-bind="record"></component>
        </template>
        <template #summary v-if="summaryColumnsShow">
          <s-table-summary-row>
            <s-table-summary-cell
              :columnKey="item.columnKey"
              v-for="item in summaryColumns"
              :key="item.index"
            >
              <div v-if="item.index == 0">{{ t('合计') }}</div>
              <div v-else-if="!item.summary">&nbsp;</div>
              <template #default="{ total }" v-if="item.summary" >
                  <span :style="item.color ? item.color(null, total) : null">
                    {{ item.content ? item.content(null, total) : total }}
                  </span>
              </template>
            </s-table-summary-cell>
          </s-table-summary-row>
        </template>
      </s-table>
    </a-card>
  </div>
  <form-modal
    v-bind="editModalAdd"
    v-if="editModalAdd.visible"
    :loading="confirmLoading"
    @cancel="
      () => {
        editModalAdd.visible = false;
      }
    "
    @ok="handlerOk"
  />
  <form-modal
    v-bind="editModalUpdate"
    v-if="editModalUpdate.visible"
    :loading="confirmLoading"
    @cancel="
      () => {
        editModalUpdate.visible = false;
      }
    "
    @ok="handlerOk"
  />
</template>

<script lang="ts">
import { computed, defineComponent, onActivated, onDeactivated, onMounted, reactive, ref, watch } from 'vue';
import {
  ColumnHeightOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import FormModal from '@/components/form-modal/form-modal.vue';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import getPageConfig from '@/components/page-model3/index';
import { Record } from '@/components/page-model/typing';
import { set_custom_data_by_flag } from '@/api/sys-model';
import { GET_CUSTOME_DATA_USER } from '@/store/modules/user/actions';
import modalBox from '@/components/form-modal/modal-tools';
import { update_custom_panel_data } from '@/api/sandbox-model';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PageModel2',
  props: {
    modalType: {
      type: String,
      required: true,
    },
    panel_obj: {
      type: Object,
      required: true,
    },
    search: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      required: true,
      default: () => 300,
    },
    title: {
      type: String,
      required: true,
    },
    is_show_table_btn: {
      type: Boolean,
      default: () => false,
    },
    outer_columns: {
      type: Array || Boolean,
      default: () => null,
      required: false,
    },
    column_flag: {
      type: String,
      required: true,
    },
    rowkey: {
      type: String,
      required: false,
      default: () => 'id',
    },
    mode: {
      type: String,
      required: false,
    },headerComponentObj: {
      type: Object,
      default: () => {},
    },
    is_show_toolbar: {
      type: Boolean,
      default: () => true,
    },
    is_custom_pagination: {
      type: Boolean,
      default: () => false,
    },
    is_show_excel: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    is_show_refresh: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    is_show_change_column: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    is_show_fullscreen: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    rowheight: {
      type: [String, Number],
      required:false,
    },
    virtual: {
      type: Boolean,
      default: () => false,
    },
    is_pagination: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ['ok', 'onLoad', 'refresh'],
  setup(props, { emit }) {
    const confirmLoading = ref<boolean>(false);
    const store = useStore();
    const { t } = useI18n();
    const custome_data_user = store.getters['user/custome_data_user'];
    const current_org = store.getters['user/current_org'];
    const code_ref = ref<string>('');
    const checkAll = ref<boolean>(false);
    const checkAction = ref<boolean>(false);
    let formModalUpdate = false; // 判断显示创建、修改
    const tableHeight = reactive({ y: 300 });
    const selected_id = ref(0);
    const select_model: any = reactive({
      org_id: current_org?.id,
      pageSize: 20,
    });
    const is_virtual = ref(false);
    const is_show_table = ref(false);
    const field = {
      column_flag: 'column_' + props.column_flag,
    };
    // 时间区间格式转化 数组-->字符串
    const getRangePicker = (api_fields: Record, record: Record) => {
      const obj: any = {};
      for (const key in api_fields) {
        if (record[api_fields[key] as string]) {
          obj[key] = record[api_fields[key] as string].join(',');
        }
      }
      return obj;
    };
    const changeScreenSize = () => {
      setTimeout(() => {
        if (screenState.value) {
            tableHeight.y = window.innerHeight - 180;
        } else {
              tableHeight.y = 300;
        }
      }, 300);
    };
    onMounted(() => {
      window.onresize = function () {
        changeScreenSize();
      };
    })

    const column_normal_btn_list: any = computed(() => {
      return btn_list?.filter((item: any) => item.type == undefined || item.type == 'btn');
    });
    const column_dropdown_btn_dict: any = computed(() => {
      const l = btn_list?.filter((item: any) => item.type == 'dropdown');
      if (l && l.length > 0) {
        return { dropdown_btn: l[0], btn_list: l.slice(1) };
      } else {
        return null;
      }
    });
    const {
      searchConfig,
      searchConfigOptions,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      queryData,
      addData,
      updateData,
      removeData,
      recoverData,
      baseColumns,
      column_list_change,
      slotList,
      titleList,
      api_fields,
      btn_list,
      head_btn_list,
      event_obj,
      init_request,
      selectedRowKeys,
      rowSelection,
      headerComponent,
      expandedRowRender,
      expandedRowRenderComponent,
      customCell,
      customRow,
      customHeaderCell,
      processData,
      processColumn,
      pageSize,
    } = getPageConfig(props.modalType);

    if (formModalConfig.fields) {
      for (let i = 0; i < formModalConfig.fields.length; i++) {
        let filed_dict = formModalConfig.fields[i];
        if (!filed_dict.placeholder) {
          let pre_str = filed_dict.type == 'input' ? t('请填写') : t('请选择');
          filed_dict.placeholder = pre_str + filed_dict.label;
        }
      }
    }
    const event_obj_refresh = () => {
      reload();
    };
    event_obj?.on('refresh', event_obj_refresh);

    onActivated(() => {
      reload();
      event_obj?.off('refresh', event_obj_refresh);
      // 先解监听，再监听，防止重复
      event_obj?.on('refresh', event_obj_refresh);
    });
    onDeactivated(() => {
      event_obj?.off('refresh', event_obj_refresh);
    });

    let tmpBaseColumns = null;
    const column_flag = 'page-model3—' + props.modalType + '-' + props.panel_obj.id;
    if (custome_data_user && custome_data_user[column_flag]) {
      baseColumns.map((item: any, index: number) => {
        if (custome_data_user[column_flag][item.dataIndex]) {
          item.sort_num = index;
          item.sort_num = custome_data_user[column_flag][item.dataIndex]['index'];
          item.checked = custome_data_user[column_flag][item.dataIndex]['checked'];
        }
      });
      tmpBaseColumns = baseColumns.sort((a: any, b: any) => a.sort_num - b.sort_num);
    }

    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
      configState,
      update_column_org_config,
      update_column_data,
      resizeColumn,
      switch_column_config,
      columnAction,
      column_change,
      replace_column,
    } = useTableDynamicColumns(
      props.outer_columns ? props.outer_columns as TableColumn[] : baseColumns as TableColumn[],
      {
        checkAll: false,
        needRowIndex: false,
      },
      store,
      field.column_flag,
      column_list_change,
    );

    watch(
      () => dynamicColumnItems,
      () => {
        const column_data_json: any = {};
        dynamicColumnItems.value.map((item, index) => {
          column_data_json[item.key] = { key: item.key, index: index, checked: item.checked };
        });

        set_custom_data_by_flag({
          org_id: current_org?.id,
          flag: column_flag,
          data: JSON.stringify(column_data_json),
        }).then(function () {
          store.dispatch(`user/${GET_CUSTOME_DATA_USER}`, { org_id: Number(current_org?.id) });
        });
      },
      { deep: true },
    );

    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();
    // const { resetFields, validateInfos } = useForm(searchConfig.model, searchConfig.rules);
    const reload = () => {
      let is_ok = true;
      if (searchConfig.need_fields) {
        for (let k of searchConfig.need_fields) {
          if (state.requestParams) {
            if (state.requestParams[k] === null || state.requestParams[k] === undefined) {
              is_ok = false;
            }
          }
        }
      }
      if (is_ok) {
        reload_data();
      }
    };
    const {
      stripe,
      reload: reload_data,
      setPageInfo,
      context: state,
    } = useFetchData(
      queryData,
      {
        current: 1,
        pageSize: pageSize?pageSize:50,
        tableSize: 'small', // 'default' | 'middle' | 'small'
        ...props.search,
        init_request: !(init_request === false),
        requestParams: {
          ...searchConfig.model,
          ...props.search.requestParams,
        },
      },
      {
        onLoad: (dataSource: any[]) => {
          emit('onLoad', dataSource);
          if (props.mode == 'radio' && state.dataSource.length > 0) {
            emit('ok', state.dataSource[0]);
            selected_id.value = state.dataSource[0][props.rowkey];
          }
          if (state.dataSource.length > 1000) {
            is_virtual.value = true;
          }
        },
      },
      processData,
      processColumn,
      replace_column,
    );
    const summaryColumns = computed(() => {
      return dynamicColumns.value
        .filter((item: any) => {
          if (item.checked === true) {
            return true;
          } else if (item.checked === undefined) {
            return true;
          }
          return false;
        })
        .map((item: any, index: number) => {
          const obj = { index: index, columnKey:item.dataIndex, summary: item.summary, color: item.color, content:item.content };
          return obj;
        });
    });
    const custom = (record: any, index: any) => {
      if (customRow) {
        const res = customRow(record, index);
        if (res.onClick == undefined) {
          res.onClick = () => {
            if (props.mode == 'radio') {
              emit('ok', record);
              selected_id.value = record[props.rowkey];
            }
          };
        } else {
          res.onClick = (event: any) => {
            res.onClick(event);
            if (props.mode == 'radio') {
              emit('ok', record);
              selected_id.value = record[props.rowkey];
            }
          };
        }
        return res;
      } else {
        return {
          onClick: () => {
            if (props.mode == 'radio') {
              emit('ok', record);
              selected_id.value = record[props.rowkey];
            }
          },
        };
      }
    };
    const setRowClassName = (record: any) => {
      return record[props.rowkey] === selected_id.value ? 'mytable clickRowStyl6' : ''; //赋予点击行样式
    };
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      let filter_map: any = {};
      for (let key in filters) {
        filter_map[key] = filters[key].map((x: any) => x.toString()).join(',');
      }
      setPageInfo({
        current: current ? current : state.current,
        pageSize: pageSize ? pageSize : state.pageSize,
        ...props.search.requestParams,
        ...filter_map,
      });
      reload();
    };
    watch(
      () => props.search.requestParams,
      () => {
        setPageInfo({
          current: state.current,
          pageSize: state.pageSize,
          ...props.search.requestParams,
          ...searchConfig.model,
          ...getRangePicker(api_fields.rangepicker, searchConfig.model),
        });
        reload();
      },
      { deep: true },
    );
    // edit
    const editModalAdd = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: formModalConfig,
      options: formModalConfigOptions,
    });
    const editModalUpdate = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: updateFormModalConfig,
      options: updateFormModalConfigOptions,
    });
    let currentRecord = reactive({ name: '' });
    const handleOpenEdit = (record: any) => {
      formModalUpdate = true;
      editModalUpdate.visible = true;
      editModalUpdate.title = titleList.updateTitle;
      editModalUpdate.form.model = {
        ...updateFormModalConfig.model,
        ...record,
        ...getObjList(api_fields.update, record),
      };

      currentRecord = record;
    };
    const handleAdd = () => {
      formModalUpdate = false;
      editModalAdd.visible = true;

      editModalAdd.form.model = { ...formModalConfig.model };
      editModalAdd.title = titleList.addModalTitle;
    };

    const handleDelete = (record: any) => {
      removeData({
        org_id: current_org.id,
        ...getObjList(api_fields.delete, record),
      }).then((res: any) => {
        message.success(t('删除成功'));
        Object.assign(record, res);
      });
    };
    const handleRecover = (record: any) => {
      recoverData({
        org_id: current_org.id,
        ...getObjList(api_fields.recover, record),
      }).then((res: any) => {
        message.success(t('恢复成功'));
        Object.assign(record, res);
      });
    };

    const handlerOk = (data: any) => {
      confirmLoading.value = true;
      if (!formModalUpdate) {
        addData({ org_id: current_org.id, aim_org_id: editModalAdd.aim_org_id, ...data })
          .then(() => {
            message.success(t('创建成功'));
            reload();
            editModalAdd.visible = false;
          })
          .finally(() => {
            confirmLoading.value = false;
            console.error('finally ');
          });
      } else {
        updateData({
          ...data,
          org_id: current_org.id,
          ...getObjList(api_fields.updateData, data),
          ...getObjList(api_fields.updateData, editModalUpdate.form.model),
        })
          .then((res: any) => {
            Object.assign(currentRecord, res);
            message.success(t('修改成功'));
            editModalUpdate.visible = false;
            formModalUpdate = false;
          })
          .finally(() => {
            confirmLoading.value = false;
            console.error('finally ');
          });
      }
    };

    // 字段对应错误————>转化正确
    const getObjList = (api_fields: Record, record: Record) => {
      const obj: any = {};
      for (const key in api_fields) {
        if (record[api_fields[key] as string]) {
          obj[key] = record[api_fields[key] as string];
        }
      }
      return obj;
    };

    // 接口返回时间，格式优化
    const getDateTime = (time: any) => {
      if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
        time = time.substring(5, 16);
      } else if (time) {
        time = time.substring(0, 16);
      }
      return time;
    };
    const getDate = (time: any) => {
      if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
        time = time.substring(0, 10);
      } else if (time) {
        time = time.substring(0, 10);
      }
      return time;
    };
    const getTime = (time: any) => {
      if (time) {
        time = time.substring(0, 5);
      }
      return time;
    };
    const summaryColumnsShow = computed(() => {
      return dynamicColumns.value.filter(item => item.summary).length > 0;
    });
    const handleQueryDataSetting = () => {
      const loading = ref(false);
      if (props.panel_obj.query_code) {
        code_ref.value = props.panel_obj.query_code;
      } else {
        code_ref.value = '';
      }
      const editModal = reactive({
        visible: true,
        title: t('代码内容'),
        component: 'code-editing-modal',
        loading: loading,
        mode: 'javascript',
        params: code_ref.value,
        hold: (code: any) => {
          loading.value = true;
          return new Promise(resolve => {
            update_custom_panel_data({
              org_id: current_org?.id,
              custompanel_id: props.panel_obj.id,
              query_code: code,
            })
              .then(function (res: any) {
                message.success(t('保存成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                loading.value = false;
                resolve(null);
              });
          });
        },
      });
      modalBox(editModal);
    };
    const getMonth = (time: any) => {
      if (time) {
        time = time.substring(0, 7);
      }
      return time;
    };
    return {
      getMonth,
      getDateTime,
      getTime,
      getDate,
      getRangePicker,
      column_change,
      columnAction,
      t,
      checkAll,
      checkAction,
      slotList,
      titleList,
      tableHeight,
      state,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,

      handleAdd,
      handlerOk,
      confirmLoading,
      currentRecord,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      current_org,
      message,
      // edit
      editModalAdd,
      editModalUpdate,
      handleOpenEdit,
      handleDelete,
      handleRecover,
      handleQueryDataSetting,
      reload,
      getPageConfig,
      formModalConfig,
      formModalConfigOptions,
      updateFormModalConfig,
      updateFormModalConfigOptions,
      getObjList,
      changeScreenSize,
      api_fields,
      btn_list,
      head_btn_list,

      headerComponent,
      column_normal_btn_list,
      column_dropdown_btn_dict,
      configState,
      select_model,
      update_column_org_config,
      switch_column_config,
      update_column_data,
      is_virtual,
      is_show_table,
      custom,
      customCell: customCell ? customCell : () => {},
      customHeaderCell: customHeaderCell ? customHeaderCell : () => {},
      setRowClassName,
      resizeColumn,
      expandedRowRender,
      expandedRowRenderComponent,
      summaryColumnsShow,
      summaryColumns,
      rowSelection: rowSelection ? rowSelection : null,
      selectedRowKeys: selectedRowKeys ? selectedRowKeys : null,
    };
  },
  components: {
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    Draggable,
    DragContainer,
    FormModal,
  },
});
</script>
