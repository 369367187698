import {
  create_contract,
  delete_contract,
  recover_contract,
  update_contract,
  upload_contract_appendix,
  upload_contract_wechat_appendix,
} from '@/api/cerp-model';
import { query_custom_data_view } from '@/api/sandbox-model';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { btn_list, event_obj } from '@/components/page-model3/cerp/cerp-contract1-config';
import { newSlotList } from '@/components/page-model/cerp/cerp-contract-colums.ts';
import { fields, options, settings } from '@/components/page-model/cerp/cerp-contract-editModal.ts';
import { createVNode, reactive } from 'vue';
import { message, Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getDate, getDateTime } from '@/utils/function';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const search_config: SearchConfig = {
  settings: {},
  fields: [],
  rules: {},
  model: reactive({}),
};
const search_options = {};
const edit_model_config: SearchConfig = {
  settings: {},
  fields: [],
  rules: {},
  model: reactive({}),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: settings,
  fields: fields,
  rules: {},
  model: reactive({}),
};
const update_model_config_options = options(t);

const titleList = {
  title: '合同管理',
  addModalTitle: '新合同',
  updateTitle: '修改合同',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: false, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    contract_id: 'id',
  },
  delete: {
    contract_id: 'id',
  },
  recover: {
    contract_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    contract_id: 'id',
  },
};
const baseColumns = [
  {
    title: t('page.cerp.contract.colums.column.title.index'),
    dataIndex: 'index',
    width: 50,
    fixed: 'left',
  },
  {
    title: t('page.cerp.contract.colums.column.title.adviser__full_name'),
    width: 150,
    dataIndex: 'adviser__full_name',
    checked: true,

    fixed: 'left',
  },
  {
    title: t('page.cerp.contract.colums.column.title.adviser__employee_number'),
    width: 80,
    dataIndex: 'adviser__employee_number',
    checked: true,

    fixed: 'left',
  },
  {
    title: t('page.cerp.contract.colums.column.title.id'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.cerp.contract.colums.column.title.is_active'),
    width: 100,
    dataIndex: 'is_active',
    checked: false,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },
  {
    title: t('page.cerp.contract.colums.column.title.appendix_json_list'),
    width: 200,
    dataIndex: 'appendix_json_list',
    checked: true,
    cellComponent: 'clicklist',
    cb: (record: any) => {
      if (record && record.file_url) {
        const url: string = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.file_url);
        window.open(url, '_blank');
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
    del: (record: any, text: any, file: any) => {
      if (file.file_id) {
        Modal.confirm({
          title: t('提示'),
          content: t('是否删除') + '[' + file.name + ']',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
              let ids: any = [];
              text.forEach((itme: any) => {
                if (itme.file_id != file.file_id) {
                  ids.push(itme.file_id);
                }
              });
              if (ids.length == 0) {
                ids = 0;
              }
              upload_contract_appendix({
                contract_id: record.id,
                appendix_ids: ids,
                org_id: current_org?.id,
              })
                .then(() => {
                  message.success(t('删除成功'));
                  event_obj.emit('refresh');
                })
                .finally(() => {
                  resolve(null);
                });
            });
          },
          onCancel() {},
        });
      }
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.insurance__effective_date_time'),
    width: 110,
    dataIndex: 'insurance__effective_date_time',
    checked: false,
    content: (record: any, text: any) => {
      return getDate(text);
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.insurance__expiry_date_time'),
    width: 110,
    dataIndex: 'insurance__expiry_date_time',
    checked: false,
    content: (record: any, text: any) => {
      return getDate(text);
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.recuitment_company__company_name'),
    width: 150,
    dataIndex: 'recuitment_company__company_name',
    checked: true,

  },
  {
    title: t('page.cerp.contract.colums.column.title.start_date'),
    width: 110,
    dataIndex: 'start_date',
    checked: true,

    content: (record: any, text: any) => {
      return getDate(text);
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.end_date'),
    width: 110,
    dataIndex: 'end_date',
    checked: true,

    content: (record: any, text: any) => {
      return getDate(text);
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.is_current'),
    width: 150,
    dataIndex: 'is_current',
    checked: true,
    cellComponent: 'boolean',
    name1: t('是'),
    name2: t('否'),
  },
  {
    title: t('page.cerp.contract.colums.column.title.email_record__status'),
    width: 120,
    dataIndex: 'email_record__status',
    checked: true,

    color: (record: any) => {
      let color = '';
      switch (record.email_record__status) {
        case 0:
          color = 'font-weight: bolder';
          break;
        case 1:
          color = 'font-weight: bolder';
          break;
        case 2:
          color = 'color: #FF0000;font-weight: bolder';
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
    content: (record: any) => {
      let color = '';
      switch (record.email_record__status) {
        case 0:
          color = t('已发送');
          break;
        case 1:
          color = t('发送成功');
          break;
        case 2:
          color = t('发送失败');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.email_record__log'),
    width: 120,
    dataIndex: 'email_record__log',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.email_record__create_time'),
    width: 120,
    dataIndex: 'email_record__create_time',
    checked: true,
    content: (record: any, text: any) => {
      return getDateTime(text);
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.work_location__name'),
    width: 150,
    dataIndex: 'work_location__name',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.recuitment_company__vat_no'),
    width: 150,
    dataIndex: 'recuitment_company__vat_no',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.end_client__name'),
    width: 150,
    dataIndex: 'end_client__name',
    checked: true,

  },
  {
    title: t('page.cerp.contract.colums.column.title.creater'),
    width: 150,
    dataIndex: 'creater',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.termination_date'),
    width: 150,
    dataIndex: 'termination_date',
    checked: true,

    content: (record: any, text: any) => {
      return getDate(text);
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.rate_type'),
    width: 150,
    dataIndex: 'rate_type',
    checked: true,

    content: (record: any) => {
      let color = '';
      switch (record.insurance_type) {
        case 0:
          color = t('日薪');
          break;
        case 1:
          color = t('月薪');
          ;
          break;
        case 2:
          color = t('年薪');
          ;
          break;
        case 3:
          color = t('时薪');
          ;
          break;
        case 4:
          color = t('周薪');
          ;
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.currency__currency'),
    width: 150,
    dataIndex: 'currency__currency',
    checked: true,

  },
  {
    title: t('page.cerp.contract.colums.column.title.pay_currency__currency'),
    width: 150,
    dataIndex: 'pay_currency__currency',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.basic_rate'),
    width: 150,
    dataIndex: 'basic_rate',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.contract.colums.column.title.bank_charge'),
    width: 150,
    dataIndex: 'bank_charge',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.contract.colums.column.title.accommodation_rate'),
    width: 150,
    dataIndex: 'accommodation_rate',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.contract.colums.column.title.overtime_rate'),
    width: 150,
    dataIndex: 'overtime_rate',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.contract.colums.column.title.is_three_upload'),
    width: 150,
    dataIndex: 'is_three_upload',
    checked: true,
    cellComponent: 'boolean',
    name1: t('是'),
    name2: t('否'),
  },
  {
    title: t('page.cerp.contract.colums.column.title.is_double_upload'),
    width: 150,
    dataIndex: 'is_double_upload',
    checked: true,
    cellComponent: 'boolean',
    name1: t('是'),
    name2: t('否'),
  },
  {
    title: t('page.cerp.contract.colums.column.title.remark'),
    width: 150,
    dataIndex: 'remark',
    checked: true,
    cellComponent: 'remark',
  },
  {
    title: t('page.cerp.contract.colums.column.title.tip__name'),
    width: 150,
    dataIndex: 'tip__name',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.insurance_type'),
    width: 180,
    dataIndex: 'insurance_type',
    checked: true,
    content: (record: any) => {
      let color = '';
      switch (record.insurance_type) {
        case 0:
          color = t('需要');
          break;
        case 1:
          color = t('不需要–顾问在本地');
          break;
        case 2:
          color = t('不需要–顾问自己有保单');
          break;
        case 3:
          color = t('不需要–顾问公司保险');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
    filterMultiple: false,
    filters: [
      { value: '', text: t('全部') },
      { value: 0, text: t('需要') },
      { value: 1, text: t('不需要–顾问在本地') },
      { value: 2, text: t('不需要–顾问自己有保单') },
      { value: 3, text: t('不需要–顾问公司保险') },
    ],
  },
  {
    title: t('page.cerp.contract.colums.column.title.cc_management_fee_rate'),
    width: 150,
    dataIndex: 'cc_management_fee_rate',
    checked: true,
    cellComponent: 'percent',
  },
  {
    title: t('page.cerp.contract.colums.column.title.cc_management_fee'),
    width: 150,
    dataIndex: 'cc_management_fee',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.contract.colums.column.title.cc_company__name'),
    width: 150,
    dataIndex: 'cc_company__name',
    checked: true,

  },
  {
    title: t('page.cerp.contract.colums.column.title.local_management_fee'),
    width: 150,
    dataIndex: 'local_management_fee',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.contract.colums.column.title.local_management_fee_rate'),
    width: 150,
    dataIndex: 'local_management_fee_rate',
    checked: true,
    cellComponent: 'percent',
  },
  {
    title: t('page.cerp.contract.colums.column.title.local_management_fee_fixed'),
    width: 150,
    dataIndex: 'local_management_fee_fixed',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.contract.colums.column.title.local_pay_currency__currency'),
    width: 150,
    dataIndex: 'local_pay_currency__currency',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.local_deduct_fee'),
    width: 150,
    dataIndex: 'local_deduct_fee',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.contract.colums.column.title.local_salary'),
    width: 150,
    dataIndex: 'local_salary',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.contract.colums.column.title.is_bank_charge_payment'),
    width: 150,
    dataIndex: 'is_bank_charge_payment',
    checked: true,
    content: (record: any) => {
      let color = '';
      switch (record.is_bank_charge_payment) {
        case true:
          color = t('顾问支付');
          break;
        case false:
          color = t('顾问公司支付');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.insurance_payment_flag'),
    width: 150,
    dataIndex: 'insurance_payment_flag',
    checked: true,
    content: (record: any) => {
      let color = '';
      switch (record.insurance_payment_flag) {
        case 0:
          color = t('顾问支付');
          break;
        case 1:
          color = t('顾问公司支付');
          break;
        case -1:
          color = t('无');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.management_fee_payment_flag'),
    width: 150,
    dataIndex: 'management_fee_payment_flag',
    checked: true,
    content: (record: any) => {
      let color = '';
      switch (record.management_fee_payment_flag) {
        case 0:
          color = t('顾问支付');
          break;
        case 1:
          color = t('顾问公司支付');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.qiyuesuo_status'),
    width: 150,
    dataIndex: 'qiyuesuo_status',
    checked: true,
    content: (record: any) => {
      let color = '';
      switch (record.qiyuesuo_status) {
        case 1:
          color = t('草稿');
          break;
        case 2:
          color = t('发起合同');
          break;
        case 3:
          color = t('顾问签署');
          break;
        case 4:
          color = t('签署完成');
          break;
        case 5:
          color = t('手动发送');
          break;
        default:
          color = t('无');
          break;
      }
      return color;
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.management_fee_formula__name'),
    width: 150,
    dataIndex: 'management_fee_formula__name',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.cc_email__name'),
    width: 150,
    dataIndex: 'cc_email__name',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.invoice_email_template__name'),
    width: 150,
    dataIndex: 'invoice_email_template__name',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.payslip_email_template__name'),
    width: 150,
    dataIndex: 'payslip_email_template__name',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.wechat_appendix_json_list'),
    width: 200,
    dataIndex: 'wechat_appendix_json_list',
    checked: true,
    cellComponent: 'clicklist',
    cb: (record: any) => {
      if (record && record.file_url) {
        const url: string = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.file_url);
        window.open(url, '_blank');
        // axios
        //   .get('/' + record.file_url, {
        //     responseType: 'blob',
        //   })
        //   .then((res: any) => {
        //     const blob = res.data;
        //     // FileReader主要用于将文件内容读入内存
        //     const reader = new FileReader();
        //     reader.readAsDataURL(blob);
        //     // onload当读取操作成功完成时调用
        //     reader.onload = (e: any) => {
        //       const a = document.createElement('a');
        //       // 获取文件名fileName
        //       let fileName = res.config['url'].split('/');
        //       fileName = fileName[fileName.length - 1];
        //       // fileName = fileName.replace(/"/g, "");
        //       a.download = fileName;
        //       a.href = e.target.result;
        //       document.body.appendChild(a);
        //       a.click();
        //       document.body.removeChild(a);
        //     };
        //   });
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
    del: (record: any, text: any, file: any) => {
      if (file.file_id) {
        Modal.confirm({
          title: t('提示'),
          content: t('是否删除') + '[' + file.name + ']',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
              let ids: any = [];
              text.forEach((itme: any) => {
                if (itme.file_id != file.file_id) {
                  ids.push(itme.file_id);
                }
              });
              if (ids.length == 0) {
                ids = 0;
              }
              upload_contract_wechat_appendix({
                contract_id: record.id,
                appendix_ids: ids,
                org_id: current_org?.id,
              })
                .then(() => {
                  message.success(t('删除成功'));
                  event_obj.emit('refresh');
                })
                .finally(() => {
                  resolve(null);
                });
            });
          },
          onCancel() {},
        });
      }
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.create_time'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,

    content: (record: any, text: any) => {
      return getDateTime(text);
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.update_time'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any, text: any) => {
      return getDateTime(text);
    },
  },

  {
    title: t('page.cerp.contract.colums.column.title.action'),
    width: 120,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
newSlotList.push(
  {
    type: 'custom',
    slotName: 'rate_type',
    field_0: t('日薪'),
    field_1: t('月薪'),
    field_2: t('年薪'),
    field_3: t('时薪'),
    field_4: t('周薪'),
  },
  {
    type: 'custom',
    slotName: 'is_bank_charge_payment',
    field_true: t('顾问支付'),
    field_false: t('顾问公司支付'),
  },
  {
    type: 'custom1',
    slotName: 'insurance_payment_flag',
    content: (record: any) => {
      if (record.insurance_payment_flag == -1) {
        return t('无');
      } else if (record.insurance_payment_flag == 0) {
        return t('顾问支付');
      } else if (record.insurance_payment_flag == 1) {
        return t('顾问公司支付');
      } else {
        return '';
      }
    },
  },
  {
    type: 'custom',
    slotName: 'management_fee_payment_flag',
    field_0: t('顾问支付'),
    field_1: t('顾问公司支付'),
  },
  {
    type: 'clickList',
    slotName: 'appendix_json',
    cb: (record: any) => {
      if (record && record.file_url) {
        const url: string = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.file_url);
        window.open(url, '_blank');
        // axios.get('/' + record.file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
    del: (record: any, text: any, file: any) => {
      if (file.file_id) {
        Modal.confirm({
          title: t('提示'),
          content: t('是否删除') + '[' + file.name + ']',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              let ids: any = [];
              text.forEach((itme: any) => {
                if (itme.file_id != file.file_id) {
                  ids.push(itme.file_id);
                }
              });
              if (ids.length == 0) {
                ids = 0;
              }
              upload_contract_appendix({
                contract_id: record.id,
                appendix_ids: ids,
                org_id: current_org?.id,
              })
                .then(() => {
                  message.success(t('删除成功'));
                  event_obj.emit('refresh');
                })
                .finally(() => {
                  resolve(null);
                });
            });
          },
          onCancel() {},
        });
      }
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.tip_day'),
    width: 150,
    dataIndex: 'tip_day',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.expiration_email_template__name'),
    width: 150,
    dataIndex: 'expiration_email_template__name',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.expiration_email_record__status'),
    width: 120,
    dataIndex: 'expiration_email_record__status',
    checked: true,
    sorter: {
      multiple: 1,
    },
    color: (record: any) => {
      let color = '';
      switch (record.expiration_email_record__status) {
        case 0:
          color = 'font-weight: bolder';
          break;
        case 1:
          color = 'font-weight: bolder';
          break;
        case 2:
          color = 'color: #FF0000;font-weight: bolder';
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
    content: (record: any) => {
      let color = '';
      switch (record.expiration_email_record__status) {
        case 0:
          color = t('已发送');
          break;
        case 1:
          color = t('发送成功');
          break;
        case 2:
          color = t('发送失败');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.expiration_email_record__log'),
    width: 120,
    dataIndex: 'expiration_email_record__log',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.expiration_email_record__create_time'),
    width: 120,
    dataIndex: 'expiration_email_record__create_time',
    checked: true,
    content: (record: any, text: any) => {
      return getDateTime(text);
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.sign_email_template__name'),
    width: 150,
    dataIndex: 'sign_email_template__name',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.sign_email_record__status'),
    width: 120,
    dataIndex: 'sign_email_record__status',
    checked: true,
    sorter: {
      multiple: 1,
    },
    color: (record: any) => {
      let color = '';
      switch (record.sign_email_record__status) {
        case 0:
          color = 'font-weight: bolder';
          break;
        case 1:
          color = 'font-weight: bolder';
          break;
        case 2:
          color = 'color: #FF0000;font-weight: bolder';
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
    content: (record: any) => {
      let color = '';
      switch (record.sign_email_record__status) {
        case 0:
          color = t('已发送');
          break;
        case 1:
          color = t('发送成功');
          break;
        case 2:
          color = t('发送失败');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
  },
  {
    title: t('page.cerp.contract.colums.column.title.sign_email_record__log'),
    width: 120,
    dataIndex: 'sign_email_record__log',
    checked: true,
  },
  {
    title: t('page.cerp.contract.colums.column.title.sign_email_record__create_time'),
    width: 120,
    dataIndex: 'sign_email_record__create_time',
    checked: true,
    content: (record: any, text: any) => {
      return getDateTime(text);
    },
  },
);
export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_custom_data_view,
    addData: create_contract,
    updateData: update_contract,
    removeData: delete_contract,
    recoverData: recover_contract,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: [],
    event_obj: event_obj,
  };
};
