import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import { useRouter } from 'vue-router';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { t } = useI18n();
const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const True = true;
const False = false;
const trim = (str: any) => {
  return str.replace(/(^\s*)|(\s*$)/g, "");
}
export const event_obj = mitt();
export const btn_list = [
  {
    name: '跳转',
    clazz: 'primary',
    cb: (record: any) => {
      router.push({path: '/adviser-manage/cerp-adviser', query: {ids: [parseInt(record.id)], t: (new Date()).getTime()}});
    },
  },
  // {
  //   name: '修改',
  //   type: 'dropdown',
  //   clazz: 'primary',
  //   cb: (record: any) => {
  //     const {
  //       settings_adviser,
  //       fields_adviser,
  //       fields_adviser_update,
  //       options_adviser,
  //       baseColumns,
  //     } = cerp_adviser_editModal(t, record);
  //     let is_update = false;
  //     const editModal = {
  //       visible: true,
  //       title: '修改顾问',
  //       loading: false,
  //       form: {
  //         settings: settings_adviser,
  //         fields: fields_adviser_update,
  //         rules: {},
  //         model: reactive({
  //           org_id: current_org?.id,
  //           is_active: true,
  //           adviser_id: record?.id,
  //           employee_number: record?.employee_number,
  //           title: record?.title,
  //           first_name: record?.first_name,
  //           last_name: record?.last_name,
  //           other_name: record?.other_name,
  //           birth_date: record?.birth_date,
  //           nationality: record?.nationality,
  //           pay_currency_id: record?.pay_currency_id,
  //           person_type_id: record?.person_type_id,
  //           mobile: record?.mobile,
  //           phone: record?.phone,
  //           personal_email: record?.personal_email,
  //           work_email: record?.work_email,
  //           work_address_id: record?.work_address_id,
  //           creater_id: record?.creater_id,
  //           status: record?.status,
  //           end_date: record?.end_date,
  //           wechat: record?.wechat,
  //           qq: record?.qq,
  //           skypelid: record?.skypelid,
  //           whats_app: record?.whats_app,
  //           home_address_id: record?.home_address_id,
  //           person_note: record?.person_note,
  //           creater: record?.creater,
  //           cc_bank_account_id: record?.cc_bank_account_id,
  //         }),
  //         watch: {
  //           creater_id: (data: any) => {
  //             query_user_list({
  //               org_id: current_org?.id,
  //               user_ids: [data.creater_id].join(','),
  //             }).then((res: any) => {
  //               data['creater'] = res.data[0].realname;
  //             });
  //           },
  //           status: (data: any) => {
  //             is_update = true;
  //           },
  //         },
  //       },
  //       options: options_adviser,
  //       ok: (payload: any) => {
  //         return new Promise(resolve => {
  //           if(is_update && payload.end_date){
  //               notification.error({
  //                 message: t('提示'),
  //                 description: '请先删除顾问结束日期',
  //               });
  //               return false;
  //           }
  //           editModal.loading = true;
  //           update_adviser({
  //             ...payload,
  //             work_address_id: payload.work_address_id ? payload.work_address_id : '',
  //             first_name: trim(payload.first_name),
  //             last_name: trim(payload.last_name),
  //             phone: payload.phone?.trim(),
  //             mobile: payload.mobile?.trim(),
  //           }).then((param: any) => {
  //               message.success('修改成功');
  //               event_obj.emit('refresh');
  //               resolve(null);
  //             })
  //             .finally(() => {
  //               editModal.loading = false;
  //             });
  //         });
  //       },
  //     };
  //     modalBox(editModal);
  //   },
  // },
  // {
  //   name: '备注',
  //   type: 'dropdown',
  //   clazz: 'primary',
  //   cb: (record: any) => {
  //     const modifyModal: any = reactive({
  //       visible: false,
  //       component: 'NoteModel',
  //       params: {
  //         object_id: record.id,
  //         note_type_flag: 'cerp.adviser',
  //       },
  //     });
  //     modifyModal.visible = true;
  //     modifyModal.ok = () => {
  //       event_obj.emit('refresh');
  //     };
  //     modifyModal.cancel = () => {};
  //     modalBox(modifyModal);
  //   },
  // },
];

export const slotList = defaultSlotList;
export const config = {
  creatCol: [6, 6, 6],
  updateCol: [6, 5, 5],
};

export const head_btn_list: btnTd[] = [];
