import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { reactive } from 'vue';
import { create_note, query_note_list } from '@/api/cerp-model';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import table_info_modal from '@/components/info-modal/table_info_modal';
import router from "@/router";

export const event_obj = mitt();
export const btn_list = [
  {
    name: '跳转',
    clazz: 'primary',
    cb: (record: any) => {
      router.push({path: '/adviser-manage/cerp-todoevent', query: {ids: [parseInt(record.id)], t: (new Date()).getTime()}});
    },
  },
  // {
  //   name: '备注',
  //   type: 'dropdown',
  //   clazz: 'primary',
  //   cb: (record: any) => {
  //     const modifyModal: any = reactive({
  //       visible: false,
  //       component: 'NoteModel',
  //       params: {
  //         object_id: record.id,
  //         note_type_flag: 'cerp.remittance',
  //       },
  //     });
  //     modifyModal.visible = true;
  //     modifyModal.ok = () => {
  //       event_obj.emit('refresh');
  //     };
  //     modifyModal.cancel = () => {};
  //     modalBox(modifyModal);
  //   },
  // },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
