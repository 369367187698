import { create_todoevent, delete_todoevent, recover_todoevent, update_todoevent } from '@/api/cerp-model';

import { query_custom_data_view } from '@/api/sandbox-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { btn_list, config, event_obj, slotList } from '@/components/page-model3/cerp/cerp-todoevent-config';
import { useI18n } from 'vue-i18n';

import { reactive } from 'vue';

const { t } = useI18n();
const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'q',
      label: '模糊搜索',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写搜索内容',
    },

    {
      type: 'select',
      name: 'is_finished',
      label: '是否完成',
      label_i18n: '是否完成',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择是否完成',
      datasource: 'is_active',
    },
    {
      type: 'select',
      name: 'is_active',
      label: '状态',
      label_i18n: '状态',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择状态',
      datasource: 'is_active',
    },
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '顾问',
      label_i18n: '顾问',
      placeholder_i18n: '请选择隶属顾问',
      disabled: false,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'select',
      name: 'status',
      label: '紧迫状态',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择紧迫状态',
      datasource: 'status',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    q: '',
    is_finished: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    {
      value: True,
      name: '可用',
    },
    { value: False, name: '禁用' },
  ],
  is_finished: [
    { value: None, name: '全部' },
    {
      value: True,
      name: '完成',
    },
    { value: False, name: '未完成' },
  ],
  status: [
    { value: 0, name: '普通' },
    { value: 1, name: '紧急' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'title',
      label: '标题',
      label_i18n: '标题',
      placeholder_i18n: '请填写标题',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写标题',
    },
    {
      type: 'input',
      name: 'content',
      label: '内容',
      label_i18n: '内容',
      placeholder_i18n: '请填写内容',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写内容',
    },
    {
      type: 'radio',
      name: 'is_finished',
      label: '是否完成',
      label_i18n: '是否完成',
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '顾问',
      label_i18n: '顾问',
      placeholder_i18n: '请选择隶属顾问',
      disabled: false,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'select',
      name: 'status',
      label: '紧迫状态',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择紧迫状态',
      datasource: 'status',
    },
  ],
  rules: {
    title: [{ required: true, message: '请填输入标题' }],
    content: [{ required: true, message: '请填输入内容' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    title: null,
    content: null,
    is_finished: null,
  }),
};
const edit_model_config_options = {
  is_finished: [
    { value: True, name: '完成' },
    { value: False, name: '未完成' },
  ],
  status: [
    { value: 0, name: '普通' },
    { value: 1, name: '紧急' },
  ],
};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'title',
      label: '标题',
      label_i18n: '标题',
      placeholder_i18n: '请填写标题',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写标题',
    },
    {
      type: 'input',
      name: 'content',
      label: '内容',
      label_i18n: '内容',
      placeholder_i18n: '请填写内容',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写内容',
    },
    {
      type: 'radio',
      name: 'is_finished',
      label: '是否完成',
      label_i18n: '是否完成',
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '顾问',
      label_i18n: '顾问',
      placeholder_i18n: '请选择隶属顾问',
      disabled: false,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'select',
      name: 'status',
      label: '保险类别',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择保险类别',
      datasource: 'status',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    title: null,
    content: null,
    is_finished: null,
  }),
};
const update_model_config_options = {
  is_finished: [
    { value: True, name: '完成' },
    { value: False, name: '未完成' },
  ],
  status: [
    { value: 0, name: '普通' },
    { value: 1, name: '紧急' },
  ],
};

const titleList = {
  title: '自定义事件管理',
  addModalTitle: '新建自定义事件',
  updateTitle: '修改自定义事件',
  is_create: false, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    todoevent_id: 'id',
  },
  delete: {
    todoevent_id: 'id',
  },
  recover: {
    todoevent_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    todoevent_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
  },

  {
    title: t('标题'),
    width: 150,
    dataIndex: 'title',
    checked: true,
  },
  {
    title: t('内容'),
    width: 150,
    dataIndex: 'content',
    checked: true,
  },
  {
    title: t('优先等级'),
    width: 150,
    dataIndex: 'todo_priority__name',
    checked: true,
  },
  {
    title: t('顾问名称'),
    width: 150,
    dataIndex: 'adviser__full_name',
    checked: true,
  },
  {
    title: t('顾问工号'),
    width: 100,
    dataIndex: 'adviser__employee_number',
    checked: true,
  },

  {
    title: t('执行人'),
    width: 150,
    dataIndex: 'person_name',
    checked: true,
  },
  {
    title: t('执行人手机号'),
    width: 150,
    dataIndex: 'person_phone',
    checked: true,
  },
  {
    title: t('是否完成'),
    width: 150,
    dataIndex: 'is_finished',
    checked: true,
    slots: { customRender: 'bool' },
  },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,

    slots: { customRender: 'datetime' },
  },
  {
    title: t('更新时间'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,

    slots: { customRender: 'datetime' },
  },
  {
    title: t('是否可用'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,

    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('操作'),
    width: 200,
    checked: false,
    dataIndex: 'action',
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
// newSlotList.push({
//   type: 'custom',
//   slotName: 'is_active',
//   field_true: '可用',
//   field_false: '禁用',
// });

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_custom_data_view,
    addData: create_todoevent,
    updateData: update_todoevent,
    removeData: delete_todoevent,
    recoverData: recover_todoevent,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: [],
    event_obj: event_obj,
  };
};
