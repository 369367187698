import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import router from "@/router";
export const event_obj = mitt();
export const btn_list = [
  {
    name: '跳转',
    clazz: 'primary',
    cb: (record: any) => {
      router.push({path: '/adviser-manage/cerp-wechatupdaterecord', query: {ids: [parseInt(record.id)], t: (new Date()).getTime()}});
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
