import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  send_invoice_by_email,
  cancel_invoice,
  resume_invoice,
  modify_invoice_mangefee,
  create_invoice_pdf,
  query_note_list,
  create_remittance, send_invoice_by_manul,
} from '@/api/cerp-model';
import { message, Modal } from 'ant-design-vue';
import { reactive, ref } from 'vue';
import modalBox from '@/components/form-modal/modal-tools';
import table_info_modal from '@/components/info-modal/table_info_modal';
import { useRouter } from 'vue-router';
const router = useRouter();

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
export const event_obj = mitt();
export const btn_list = [
  {
    name: '跳转',
    clazz: 'primary',
    cb: (record: any) => {
      router.push({path: '/adviser-manage/cerp-invoice', query: {id: [parseInt(record.id)], t: (new Date()).getTime()}});
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [6, 6],
  updateCol: [6, 6],
};

export const head_btn_list = [];
