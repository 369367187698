<template>
  <page-container3>
  <grid-content>
<!--    <a-row :gutter="24" style="margin-top: 10px">-->
      <div
        v-for="card in tableList"
        :key="card.title"
        style="margin-bottom: 10px"
      >
        <page-model-3
          :modalType="card.modalType"
          :search="card.search"
          :title="card.title"
          :panel_obj="card.panel_obj"
        />
      </div>
<!--    </a-row>-->
  </grid-content>
  </page-container3>
</template>

<script lang="ts">
import { computed, defineComponent, onActivated, onDeactivated, reactive, ref, toRefs } from 'vue';
import { GridContent } from '@/components';
import { useStore } from 'vuex';
import Info from '@/views/list/basic-list/info.vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

import PageModel3 from '@/components/page-model3/index.vue';

import { query_custom_panel_list } from '@/api/sandbox-model';
import bus from '@/utils/bus';

export default defineComponent({
  name: 'Workplace3',
  setup() {
    const store = useStore();
    const state = reactive({
      tabList: [
        { key: 'my', tab: '我的' },
        { key: 'fov', tab: '我的收藏' },
      ],
    });
    const table_dict: any = {
      'cerp-adviser': {
        title: '顾问',
        search: {},
        modalType: 'cerp-adviser',
      },
      'cerp-todoevent': {
        title: '待办事项',
        search: {},
        modalType: 'cerp-todoevent',
      },
      'cerp-contract': {
        title: '合同',
        search: {},
        modalType: 'cerp-contract',
      },
      'cerp-insurance': {
        title: '保险',
        search: {},
        modalType: 'cerp-insurance',
      },
    };
    const current_company = computed(() => store.getters['user/current_company']);

    const tableList: any = ref([]);
    let list: any = [];
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    // const flag_prefix = 'workplace3_';
    // const flag_list = [
    //   'workplace3_cerp-adviser',
    //   'workplace3_cerp-todoevent',
    //   'workplace3_cerp-contract',
    //   'workplace3_cerp-insurance',
    // ];
    const refresh = () => {
      tableList.value.length = 0;
      list = [];
      query_custom_panel_list({
        org_id: current_org?.id,
        is_active: true,
        pageSize: 200,
      }).then(function (res: any) {
        for (let obj of res.data) {
          var item: any = { title: obj.name, modalType: obj.modal_type, panel_obj: obj, index: obj.sorted_index };
          let column_json: any = null;
          try {
            column_json = JSON.parse(obj.column_json);
          } catch (e) {
          }
          let arr: any = [];
          let data: any = [];
          if (current_company.value?.id && current_company.value?.id.toString().indexOf(',') != -1) {
            arr = current_company.value?.id ? current_company.value?.id.toString()?.split(',') : [];
          } else {
            data = current_company.value?.id ? [Number(current_company.value?.id)] : [];
          }
          if (arr && arr.length > 0) {
            arr.forEach((item: any) => {
              if (item) {
                data.push(Number(item));
              }
            });
          }

          item.search = {
            current: 1,
            pageSize: 20,
            tableSize: 'small', // 'default' | 'middle' | 'small'
            stripe: true,
            requestParams: {
              org_id: current_org?.id,
              script_id: obj.id,
              data: JSON.stringify({ cc_company_ids: data }),
              column_data: column_json,
            },
          };
          list.push(item);
        }
        list = list.sort((a: any, b: any) => a.index - b.index);
        list.map((item: any) => {
          tableList.value.push(item);
        });
      });
    };
    refresh();

    bus.on('selectCompany', refresh);
    onActivated(() => {
      bus?.off('selectCompany', refresh);
      bus.on('selectCompany', refresh);
    });

    onDeactivated(() => {
      bus?.off('selectCompany', refresh);
    });
    // const search = reactive({
    //   current: 1,
    //   pageSize: 20,
    //   tableSize: 'small', // 'default' | 'middle' | 'small'
    //   stripe: true,
    //   requestParams: {
    //     org_id: current_org?.id,
    //     'query_data': {
    //       'app_label': 'cerp',
    //       'model': 'Adviser',
    //       'query_params_list': [
    //         {
    //           'children': [
    //             { 'field': 'id', 'condition': '__gte', 'value': 1 },
    //             { 'field': 'id', 'condition': '__lte', 'value': 2, 'and_or': 'and' },
    //           ],
    //         },
    //         {
    //           'children': [
    //             { 'field': 'id', 'condition': '__gte', 'value': 3 },
    //             { 'field': 'id', 'condition': '__lte', 'value': 5, 'and_or': 'and' },
    //           ], 'and_or': 'or',
    //         },
    //       ],
    //     },
    //   },
    // });
    // const search2 = reactive({
    //   current: 1,
    //   pageSize: 20,
    //   tableSize: 'small', // 'default' | 'middle' | 'small'
    //   stripe: true,
    //   requestParams: {
    //     org_id: current_org?.id,
    //     'query_data': {
    //       'app_label': 'cerp',
    //       'model': 'Contract',
    //       'query_params_list': [
    //         {
    //           'children': [
    //             { 'field': 'id', 'condition': '__gte', 'value': 1 },
    //             { 'field': 'id', 'condition': '__lte', 'value': 2, 'and_or': 'and' },
    //           ],
    //         },
    //         {
    //           'children': [
    //             { 'field': 'id', 'condition': '__gte', 'value': 3 },
    //             { 'field': 'id', 'condition': '__lte', 'value': 5, 'and_or': 'and' },
    //           ], 'and_or': 'or',
    //         },
    //       ],
    //     },
    //   },
    // });

    return {
      currentUser: computed(() => store.getters[`user/currentUser`]),
      tableList,
      ...toRefs(state),
    };
  },
  components: { GridContent, Info, PageModel3 },
});
</script>

<style lang="less" scoped>
.page-header-welcome {
  background: #27a6fe;

  h1 {
    color: @text-color-inverse;
    font-size: @heading-3-size;
  }
  .desc {
    color: @text-color-inverse;
  }
}
.cover-tips {
  width: 100%;
  > img {
    display: block;
    width: 100%;
  }
}
.members {
  a {
    display: block;
    line-height: 80px;
    text-align: center;

    .member {
      display: block;
      color: @text-color;
      font-size: 14px;
      line-height: 24px;
      vertical-align: top;
      transition: all 0.3s;
    }

    &:hover {
      span {
        color: @primary-color;
      }
    }
  }
}
</style>
