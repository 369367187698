import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import router from '@/router';

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
export const event_obj = mitt();
export const btn_list = [
  {
    name: '跳转',
    clazz: 'default',
    cb: (record: any) => {
      router.push({
        path:
          '/profile/basic/' +
          record.adviser_id +
          '/' +
          record.adviser__full_name +
          '/' +
          record.adviser__employee_number +
          '/' +
          record.id,
      });
    },
  },
  // {
  //   name: '修改',
  //   type: 'dropdown',
  //   clazz: 'primary',
  //   cb: (data: any) => {
  //     const list: any = [];
  //     data.email_address_list.forEach((item: any) => {
  //       list.push(item.id);
  //     });
  //     const cc_address_ids: any = [];
  //     data.invoice_cc_address_list.forEach((item: any) => {
  //       cc_address_ids.push(item.id);
  //     });
  //     const insurance_fee = ref(false);
  //     const is_insurance_fee = ref(false);
  //     const is_c_insurance_fee = ref(false);
  //     if (data.insurance_type != 0) {
  //       insurance_fee.value = true;
  //       is_insurance_fee.value = true;
  //       is_c_insurance_fee.value = true;
  //     }
  //     if(data.insurance_payment_flag == -1){
  //       is_insurance_fee.value = true;
  //       is_c_insurance_fee.value = true;
  //     }else if(data.insurance_payment_flag == 0){
  //       is_insurance_fee.value = false;
  //       is_c_insurance_fee.value = true;
  //     }else if(data.insurance_payment_flag == 1){
  //       is_insurance_fee.value = true;
  //       is_c_insurance_fee.value = false;
  //     }
  //     const editModal = reactive({
  //       visible: true,
  //       title: '修改合同',
  //       loading: false,
  //       form: {
  //         settings: settings,
  //         fields: get_fields({is_current: true,insurance_fee: insurance_fee, is_insurance_fee: is_insurance_fee, is_c_insurance_fee: is_c_insurance_fee}),
  //         rules: {
  //           consultant: [{ required: true, message: t('请填写职务') }],
  //           recuitment_company_id: [{ required: true, message: t('请选择顾问公司') }],
  //           recuitment_company_person_id: [{ required: true, message: t('请选择请款邮箱地址') }],
  //           work_location_id: [{ required: true, message: t('请选择工作地点') }],
  //           start_end_date: [{ required: true, message: t('请填写合同起始结束日期') }],
  //           management_fee_formula_id: [{ required: true, message: t('请选择管理费计算方式') }],
  //           insurance_type: [{ required: true, message: t('请选择保险类型') }],
  //           currency_id: [{ required: true, message: t('请选择薪资币种') }],
  //           cc_company_id: [{ required: true, message: t('请选择CC公司') }],
  //           contract_template_id: [{ required: true, message: t('请选择合同模板') }],
  //           adviser_email_template_id: [
  //             { required: true, message: t('请选择请款时顾问提示邮箱模板') },
  //           ],
  //           bank_charge: [{ required: true, message: t('请填写银行转账费') }],
  //           cc_management_fee_rate: [{ required: true, message: t('请填写cc管理费率') }],
  //           basic_rate: [{ required: true, message: t('请填写基本单位薪资') }],
  //           manage_fee: [{ required: true, message: t('请填写管理费描述') }],
  //           cc_email_id: [{ required: true, message: t('请选择CC邮箱') }],
  //           invoice_email_template_id: [{ required: true, message: t('请选择请款单邮箱模板') }],
  //           payslip_email_template_id: [{ required: true, message: t('请选择工资单邮箱模板') }],
  //           end_client_id: [{ required: true, message: t('请选择终端用户') }],
  //           contract_email_template_id: [{ required: true, message: t('请选择合同邮箱模板') }],
  //           expense_email_template_id: [{ required: true, message: t('请选择报销请款单邮箱模板') }],
  //           overtime_email_template_id: [{ required: true, message: t('请选择加班请款单邮箱模板') }],
  //           cn_email_template_id: [{ required: true, message: t('请选择CN请款单邮箱模板') }],
  //         },
  //         model: reactive({
  //           org_id: current_org?.id,
  //           is_active: true,
  //           contract_id: data.id,
  //           adviser_id: Number(data.adviser_id),
  //           adviser__full_name: data.adviser__full_name,
  //           recuitment_company_id: data.recuitment_company_id,
  //           expense_email_template_id: data.expense_email_template_id,
  //           overtime_email_template_id: data.overtime_email_template_id,
  //           cn_email_template_id: data.cn_email_template_id,
  //           recuitment_company_person_id: data.recuitment_company_person_id,
  //           work_location_id: data.work_location_id,
  //           start_end_date: [data.start_date, data.end_date],
  //           termination_date: data.termination_date,
  //           rate_type: data.rate_type,
  //           currency_id: data.currency_id,
  //           pay_currency_id: data.pay_currency_id,
  //           basic_rate: data.basic_rate,
  //           cc_management_fee: data.cc_management_fee,
  //           bank_charge: data.bank_charge,
  //           accommodation_rate: data.accommodation_rate,
  //           overtime_rate: data.overtime_rate,
  //           remark: data.remark,
  //           nsfile_id: data.nsfile_id,
  //           cc_management_fee_rate: data.cc_management_fee_rate,
  //           local_management_fee: data.local_management_fee,
  //           local_management_fee_rate: data.local_management_fee_rate,
  //           is_bank_charge_payment: data.is_bank_charge_payment,
  //           insurance_payment_flag: data.insurance_payment_flag,
  //           management_fee_payment_flag: data.management_fee_payment_flag,
  //           management_fee_formula_id: data.management_fee_formula_id,
  //           local_management_fee_fixed: data.local_management_fee_fixed,
  //           exchange_rate_id: data.exchange_rate_id,
  //           is_current: data.is_current,
  //           end_client_id: data.end_client_id,
  //           local_deduct_fee: data.local_deduct_fee,
  //           cc_email_id: data.cc_email_id,
  //           contact_person_id: data.contact_person_id,
  //           adviser_email_template_id: data.adviser_email_template_id,
  //           invoice_email_template_id: data.invoice_email_template_id,
  //           payslip_email_template_id: data.payslip_email_template_id,
  //           contract_email_template_id: data.contract_email_template_id,
  //           creater: data.creater,
  //           creater_id: data.creater_id,
  //           contract_template_id: data.contract_template_id,
  //           cc_company_id: data.cc_company_id,
  //           consultant: data.consultant,
  //           insurance_fee: data.insurance_fee,
  //           c_insurance_fee: data.c_insurance_fee,
  //           insurance_type: data.insurance_type,
  //           manage_fee: data.manage_fee,
  //           project_name: data.project_name,
  //           email_address_ids: list,
  //           invoice_cc_address_ids: cc_address_ids,
  //         }),
  //         watch: {
  //           insurance_type: (data1: any) => {
  //             if (data1['insurance_type'] != 0) {
  //               insurance_fee.value = true;
  //               data1['insurance_payment_flag'] = -1;
  //             } else {
  //               insurance_fee.value = false;
  //             }
  //           },
  //           creater_id: (data: any) => {
  //             query_user_list({
  //               org_id: current_org?.id,
  //               user_ids: [data.creater_id].join(','),
  //             }).then((res: any) => {
  //               data['creater'] = res.data[0].realname;
  //             });
  //           },
  //           insurance_payment_flag: (data: any) => {
  //             if(data['insurance_payment_flag'] == -1){
  //               is_insurance_fee.value = true;
  //               is_c_insurance_fee.value = true;
  //               data['c_insurance_fee'] = 0;
  //               data['insurance_fee'] = 0;
  //             }else if(data['insurance_payment_flag'] == 0){
  //               is_insurance_fee.value = false;
  //               is_c_insurance_fee.value = true;
  //             }else if(data['insurance_payment_flag'] == 1){
  //               is_insurance_fee.value = true;
  //               is_c_insurance_fee.value = false;
  //             }
  //           },
  //           insurance_fee: (data: any) => {
  //             if(data['insurance_payment_flag'] == 0){
  //               query_insurance_list({
  //                 org_id: current_org.id,
  //                 is_current: true,
  //                 adviser_id: data.adviser_id,
  //               }).then((res: any) => {
  //                 data['c_insurance_fee'] = res.data[0].month_insurance_fee - (data['insurance_fee'] ? data['insurance_fee'] : 0);
  //               })
  //             }
  //           },
  //           c_insurance_fee: (data: any) => {
  //             if(data['insurance_payment_flag'] == 1){
  //               query_insurance_list({
  //                 org_id: current_org.id,
  //                 is_current: true,
  //                 adviser_id: data.adviser_id,
  //               }).then((res: any) => {
  //                 data['insurance_fee'] = res.data[0].month_insurance_fee - (data['c_insurance_fee'] ? data['c_insurance_fee'] : 0);
  //               })
  //             }
  //           }
  //         },
  //       },
  //       options: options,
  //       ok: (payload: any) => {
  //         return new Promise(resolve => {
  //           editModal.loading = true;
  //           update_contract({
  //             ...payload,
  //             start_date: payload.start_end_date[0],
  //             end_date: payload.start_end_date[1],
  //           })
  //             .then(() => {
  //               message.success('修改成功');
  //               event_obj.emit('refresh');
  //               resolve(null);
  //             })
  //             .finally(() => {
  //               editModal.loading = false;
  //             });
  //         });
  //       },
  //     });
  //     modalBox(editModal);
  //   },
  // },
  // {
  //   name: '备注',
  //   type: 'dropdown',
  //   clazz: 'primary',
  //   cb: (record: any) => {
  //     const modifyModal: any = reactive({
  //       visible: false,
  //       component: 'NoteModel',
  //       params: {
  //         object_id: record.id,
  //         note_type_flag: 'cerp.contract',
  //       },
  //     });
  //     modifyModal.visible = true;
  //     modifyModal.ok = () => {
  //       event_obj.emit('refresh');
  //     };
  //     modifyModal.cancel = () => {};
  //     modalBox(modifyModal);
  //   },
  // },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [10, 10, 10, 10],
  updateCol: [7, 7, 7, 7],
};

export const head_btn_list = [];
